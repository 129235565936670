import React, { useState, Suspense, lazy } from 'react';
import { useLocation, Routes, Route, useNavigate, Navigate } from 'react-router-dom';
import { UserProvider } from './contexts/userContext';
import Cookies from 'js-cookie'; // Import Cookies if needed for token checks
import ErrorBoundary from './ErrorBoundary';


// Import your LoadingScreen component
import LoadingScreen from './LoadingScreen';

// Web application styles
import './AvatarCreationPage.css';
import './styles/global.css';

// Import the state management providers
import { GoalProvider } from './GoalStateManager';
import { AchievementsProvider } from './AchievementsStateManager';
import { RecommendationProvider } from './contexts/RecommendationContext';

// Import components and pages
const SideMenu = lazy(() => import('./SideMenu'));
const TopNavigationBar = lazy(() => import('./TopNavigationBar'));
const HomePage = lazy(() => import('./HomePage'));
const EvaluationPage = lazy(() => import('./EvaluationPage'));
const DashboardPage = lazy(() => import('./DashboardPage'));
const GoalPage = lazy(() => import('./GoalPage'));
const JournalPage = lazy(() => import('./JournalPage'));
const HumanAdvicePage = lazy(() => import('./HumanAdvicePage'));
const ChallengePage = lazy(() => import('./ChallengePage'));
const CommunityPage = lazy(() => import('./CommunityPage'));
const AccountPage = lazy(() => import('./AccountPage'));
const LoginPage = lazy(() => import('./LoginPage'));
const EarthPage = lazy(() => import('./EarthPage'));
const WaterPage = lazy(() => import('./WaterPage'));
const AirPage = lazy(() => import('./AirPage'));
const FirePage = lazy(() => import('./FirePage'));
const SupportPage = lazy(() => import('./SupportPage'));
const SignUpPage = lazy(() => import('./SignUpPage'));
const AvatarCreationPage = lazy(() => import('./AvatarCreationPage'));
const GiftPage = lazy(() => import('./GiftPage'));
const RecommendationPage = lazy(() => import('./RecommendationPage'));
const InsightsAndRemindersPage = lazy(() => import('./InsightsAndRemindersPage'));
const AdminHomePage = lazy(() => import('./AdminHomePage'));
const LandingPageTopNavigationBar = lazy(() => import('./LandingPageTopNavigationBar'));
const LandingPageHome = lazy(() => import('./LandingPageHome'));
const LandingPageContact = lazy(() => import('./LandingPageContact'));
const LandingPageAboutUs = lazy(() => import('./LandingPageAboutUs'));
const QuickAdvicePage = lazy(() => import('./QuickAdvicePage'));
const UnauthorizedPage = lazy(() => import('./UnauthorizedPage')); // Import the UnauthorizedPage

// Import the ProtectedRoute component
import ProtectedRoute from './ProtectedRoute'; // New import for ProtectedRoute

function App() {
  const [sideMenuOpen, setSideMenuOpen] = useState(false);
  const location = useLocation();

  const handleSideMenuToggle = () => {
    setSideMenuOpen(!sideMenuOpen);
    console.log('Sidebar state changed:', !sideMenuOpen);
  };

  // Define paths for which the side menu should be hidden
  const hideSideMenuPaths = [
    '/login',
    '/signup',
    '/landingpage',
    '/about',
    '/contact',
    '/unauthorized',
  ];

  // Determine if the side menu should be displayed
  const shouldShowSideMenu = !hideSideMenuPaths.includes(location.pathname);
  const isLandingPage = hideSideMenuPaths.includes(location.pathname);
  const isUnauthorizedPage = location.pathname === '/unauthorized';

  // Define the render layout based on page type
  const renderLayout = () => {
    if (isUnauthorizedPage) {
      return (
        <Routes>
          <Route path="/unauthorized" element={<UnauthorizedPage />} />
          <Route path="*" element={<UnauthorizedPage />} />
        </Routes>
      );
    }

    return (
      <>
        {shouldShowSideMenu && (
          <SideMenu
            open={sideMenuOpen}
            onMenuClose={handleSideMenuToggle}
            setMobileOpen={setSideMenuOpen}
          />
        )}
        {isLandingPage ? (
          <LandingPageTopNavigationBar />
        ) : (
          <TopNavigationBar setMobileOpen={setSideMenuOpen} />
        )}
        <div
          className={`App-container ${sideMenuOpen && shouldShowSideMenu ? 'sidebar-open' : ''}`}
        >
          <main
            className={`App-content ${isLandingPage ? 'landing-content' : 'main-content'} ${
              sideMenuOpen && shouldShowSideMenu ? 'sidebar-open' : ''
            }`}
          >
            <Routes>
              {/* Public Routes */}
              <Route path="/login" element={<LoginPage />} />
              <Route path="/signup" element={<SignUpPage />} />
              <Route path="/landingpage" element={<LandingPageHome />} />
              <Route path="/about" element={<LandingPageAboutUs />} />
              <Route path="/contact" element={<LandingPageContact />} />
              <Route path="/unauthorized" element={<UnauthorizedPage />} />

              {/* Protected Routes */}
              <Route
                path="/"
                element={
                  <ProtectedRoute>
                    <HomePage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/evaluation"
                element={
                  <ProtectedRoute>
                    <EvaluationPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/dashboard"
                element={
                  <ProtectedRoute>
                    <DashboardPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/goals"
                element={
                  <ProtectedRoute>
                    <GoalPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/journal"
                element={
                  <ProtectedRoute>
                    <JournalPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/advice"
                element={
                  <ProtectedRoute>
                    <HumanAdvicePage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/challenges"
                element={
                  <ProtectedRoute>
                    <ChallengePage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/community"
                element={
                  <ProtectedRoute>
                    <CommunityPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/account"
                element={
                  <ProtectedRoute>
                    <AccountPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/earth"
                element={
                  <ProtectedRoute>
                    <EarthPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/water"
                element={
                  <ProtectedRoute>
                    <WaterPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/air"
                element={
                  <ProtectedRoute>
                    <AirPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/fire"
                element={
                  <ProtectedRoute>
                    <FirePage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/support"
                element={
                  <ProtectedRoute>
                    <SupportPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/avatar"
                element={
                  <ProtectedRoute>
                    <AvatarCreationPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/recommendations"
                element={
                  <ProtectedRoute>
                    <RecommendationPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/insightsandreminders"
                element={
                  <ProtectedRoute>
                    <InsightsAndRemindersPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/quickadvice"
                element={
                  <ProtectedRoute>
                    <QuickAdvicePage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/gift"
                element={
                  <ProtectedRoute>
                    <GiftPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/admin"
                element={
                  <ProtectedRoute>
                    <AdminHomePage />
                  </ProtectedRoute>
                }
              />

              {/* Fallback Route */}
              <Route path="*" element={<Navigate to="/unauthorized" replace />} />
            </Routes>
          </main>
        </div>
      </>
    );
  };

  return (
    <ErrorBoundary>
      <UserProvider>
        <RecommendationProvider>
          <GoalProvider>
            <AchievementsProvider>
              <div className={`App ${isLandingPage ? 'landing-page' : 'app-page'}`}>
                <Suspense fallback={<LoadingScreen />}>{renderLayout()}</Suspense>
              </div>
            </AchievementsProvider>
          </GoalProvider>
        </RecommendationProvider>
      </UserProvider>
    </ErrorBoundary>
  );
}

export default App;

/* Commented-Out Routes for Future Use or Reference */
// <Route path="/reminders" element={<RemindersPage />} /> // Not in use for the first version. combined in Insights&Reminders
// <Route path="/insights" element={<InsightsPage />} /> // Not in use for the first version. combined in Insights&Reminders
// <Route path="/achievements" element={<AchievementsPage />} /> // Not in use for the first version, merged in dashboard
// <Route path="/progress" element={<ProgressPage />} /> // Not in use for the first version, merged in dashboard
// <Route path="/settings" element={<SettingsPage />} /> // Not in use for the first version
