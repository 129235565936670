/**
 * authApiClient.js
 * 
 * This file creates and exports a custom Axios instance that handles authentication
 * and token management for API requests in our application.
 * 
 * Key features:
 * 1. Centralized API configuration: Sets up a base URL for all API requests.
 * 2. Automatic token injection: Adds the authentication token to every outgoing request.
 * 3. Token refresh mechanism: Automatically refreshes the access token if it expires.
 * 4. Error handling: Manages authentication errors and redirects to login when needed.
 * 
 * How it works:
 * - The Axios instance is created with a base URL from the environment variables.
 * - A request interceptor adds the authentication token to each request's headers.
 * - A response interceptor catches 401 (Unauthorized) errors.
 * - If a 401 error occurs, it attempts to refresh the token using the refresh token.
 * - If the token refresh is successful, it retries the original request.
 * - If the refresh fails, it clears the authentication cookies and redirects to the login page.
 * 
 * Usage:
 * Import this client in your components or services and use it for making API calls.
 * It will handle authentication automatically, allowing you to focus on the API logic.
 * 
 * Example:
 * import authApiClient from './authApiClient';
 * const response = await authApiClient.get('/some-endpoint');
 * 
 * Note: Ensure that your backend supports the token refresh mechanism as implemented here.
 */

// src/authApiClient.js
import axios from 'axios';
import Cookies from 'js-cookie';

const authApiClient = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  withCredentials: true, // Ensure cookies are included in requests
});

// Request Interceptor
authApiClient.interceptors.request.use((config) => {
  console.log('[authApiClient] request: Adding Authorization header to request');
  const token = Cookies.get('accessToken');
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`;
    console.log('[authApiClient] request: Authorization header added');
  } else {
    console.log('[authApiClient] request: No access token found in cookies');
  }
  return config;
});

// Response Interceptor
authApiClient.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    
    // Check if the error is a 401 Unauthorized and retry is not yet set
    if (error.response && error.response.status === 401 && !originalRequest._retry) {
      console.warn('[authApiClient] response: Received 401 Unauthorized, attempting token refresh');
      originalRequest._retry = true; // Mark request as retried to avoid looping
      
      const refreshToken = Cookies.get('refreshToken');
      if (!refreshToken) {
        console.error('[authApiClient] response: No refresh token found, cannot refresh access token');
        return Promise.reject(error);
      }
      
      try {
        const refreshResponse = await axios.post(
          `${process.env.REACT_APP_API_BASE_URL}/refresh-token`,
          {},
          { withCredentials: true }
        );

        // Set the new access token in the cookies
        Cookies.set('accessToken', refreshResponse.data.accessToken, {
          secure: process.env.NODE_ENV === 'production',
          sameSite: 'Strict',
          expires: 1 / 24, // 1 hour
        });

        // Update the original request with the new access token
        originalRequest.headers['Authorization'] = `Bearer ${refreshResponse.data.accessToken}`;
        console.log('[authApiClient] response: Token refreshed successfully, retrying original request');
        return authApiClient(originalRequest);
      } catch (refreshError) {
        console.error('[authApiClient] response: Token refresh failed', refreshError);
        // Redirect to login page or trigger logout
        return Promise.reject(refreshError);
      }
    }

    return Promise.reject(error);
  }
);


export default authApiClient;