// src/contexts/userContext.js

import React, { createContext, useState, useContext, useEffect, useRef } from 'react';
import Cookies from 'js-cookie';
import authApiClient from '../authApiClient';

const UserContext = createContext();

export const useUser = () => useContext(UserContext);

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const isMountedRef = useRef(true);

  console.log('[userContext] UserProvider: Initializing user context');

  const login = (userData) => {
    console.log('[userContext] login: Setting user context with userData', userData);
    const { id, username, role, profileImage } = userData; // Include profileImage
    const userDataContext = { id, username, role, profileImage };
    setUser(userDataContext);
    setIsLoading(false);
    console.log('[userContext] login: User logged in successfully:', userDataContext);
  };
  
  
  const logout = async () => {
    console.log('[userContext] logout: Attempting to log out');
    try {
      await authApiClient.post('/logout');
      Cookies.remove('accessToken');
      Cookies.remove('refreshToken');
      setUser(null);
      setIsLoading(false);
      console.log('[userContext] logout: Logged out successfully, user state cleared');
    } catch (error) {
      console.error('[userContext] logout: Logout failed:', error);
    }
  };

  const checkAuth = async () => {
    console.log('[userContext] checkAuth: Checking user authentication state');
    if (user) {
      console.log('[userContext] checkAuth: User already authenticated, skipping check');
      return;
    }
    try {
      console.log('[userContext] checkAuth: Sending request to /users/me');
      const response = await authApiClient.get('/users/me');
      console.log('[userContext] checkAuth: Response received:', response.data);
      
      if (isMountedRef.current && !user) {
        console.log('[userContext] checkAuth: Setting user data');
        setUser(response.data);
      }
    } catch (error) {
      console.error('[userContext] checkAuth: Error verifying user:', error);
      if (isMountedRef.current && user) {
        console.log('[userContext] checkAuth: Clearing user data due to error');
        setUser(null);
      }
    } finally {
      if (isMountedRef.current) {
        console.log('[userContext] checkAuth: Setting isLoading to false');
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    console.log('[userContext] useEffect: Component mounted, checking authentication');
    checkAuth();

    return () => {
      console.log('[userContext] useEffect cleanup: Component unmounting');
      isMountedRef.current = false;
    };
  }, []);

  console.log('[userContext] Current state - user:', user, 'isLoading:', isLoading);

  return (
    <UserContext.Provider value={{ user, setUser, login, logout, checkAuth, isLoading }}>
      {children}
    </UserContext.Provider>
  );
};

export default UserProvider;