//frontend/src/index.js

import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import App from './App';
import './index.css';

const theme = createTheme({
  // Add your theme configuration here
});

ReactDOM.render(
  <Router>
    <ThemeProvider theme={theme}>
      {/* Remove StripeProvider here */}
      <App />
    </ThemeProvider>
  </Router>,
  document.getElementById('root')
);
