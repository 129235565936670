import React, { createContext, useContext, useState, useCallback } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import { useSnackbar } from 'notistack';

const RecommendationContext = createContext(null);

export const useRecommendation = () => useContext(RecommendationContext);

export const RecommendationProvider = ({ children }) => {
    const [lastSearch, setLastSearch] = useState('');
    const [carouselCategories, setCarouselCategories] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [userSearches, setUserSearches] = useState([]);
    const [saving, setSaving] = useState(false);
    const [favoriteCategories, setFavoriteCategories] = useState([]);
    const { enqueueSnackbar } = useSnackbar();

    const updateLastSearch = useCallback((search) => {
        if (!search || search.trim() === '') {
            console.error("Invalid search term.");
            return;
        }
        setLastSearch(search);
    }, []);

    const refreshUserSearches = useCallback(async () => {
        const token = Cookies.get('token');
        if (!token) {
            console.log('No token found, cannot fetch user searches.');
            return;
        }

        try {
            const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/recommendations/past-searches`, {
                headers: { 'Authorization': `Bearer ${token}` },
            });
            setUserSearches(response.data.searchHistory);
        } catch (error) {
            console.error('Failed to fetch user searches', error);
            enqueueSnackbar('Failed to fetch user searches.', { variant: 'error' });
        }
    }, [enqueueSnackbar]);

    const refreshCategories = useCallback(() => {
        setLastSearch(prev => prev); // Trigger useEffect in dependent components
    }, []);

    return (
        <RecommendationContext.Provider value={{
            lastSearch,
            setLastSearch: updateLastSearch,
            carouselCategories,
            setCarouselCategories,
            isLoading,
            setIsLoading,
            error,
            setError,
            userSearches,
            setUserSearches,
            refreshUserSearches,
            refreshCategories,
            saving,
            setSaving,
            favoriteCategories,
            setFavoriteCategories
        }}>
            {children}
        </RecommendationContext.Provider>
    );
};
